import React from 'react';
import { Container, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import CustomFonts from '../components/customFont/customfonts';
import { siteMap } from './styles';

const styles = makeStyles(() => ({
  root: {
    paddingTop: 30,
    paddingBottom: 100
  },
  h2: {
    margin: 25,
    marginBottom: 10,
    fontWeight: 'bold'
  },
  skipLink: (data) => ({
    position: 'absolute',
    top: '-40px',
    left: '0',
    padding: '6px',
    backgroundColor: '#000000 !important',
    color: '#FFF !important',
    border: '2px solid transparent !important',
    fontSize: '1.4em !important',
    borderRadius: '0 !important',
    borderBottomRightRadius: '8px !important',
    '&:focus': {
      left: '0',
      top: '0',
      transition: 'top 1s ease-out',
      zIndex: '9999'
    }
  }),
  main: {
    '& a': {
      borderBottom: '1px solid'
    }
  }
}));

export default function Sitemap({ pageContext }) {
  const linkStyles = siteMap(pageContext?.theme);
  const classes = styles(pageContext);
  return (
    <>
      {/* <a href="#main" className={classes.skipLink}>Skip to main content</a> */}
      {/* <div id="consent_blackbar" className="cookie-consent"> </div> */}
      {/* <div id="main" className={classes.main} tabIndex="-1" role="main"> */}
      <CustomFonts data={pageContext?.theme?.typography} />
      <Layout
        partnerCode={pageContext.partnerCode}
        navigation={pageContext.appData.navigation}
        footer={pageContext.appData.siteFooter}
        styles={pageContext.theme}
      >
        <Container maxWidth="md" className={classes.root}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h3" className={classes.h2}>Sitemap</Typography>
            </Grid>
            <Grid item xs={12}>
              <ul>
                {
                  pageContext?.pages
                    .map((page) => (
                      <li className={linkStyles.space}><Link className={linkStyles.underline} key={page.path} to={`../${page.path}`}>{page.label}</Link></li>
                    ))
                }
              </ul>
            </Grid>
          </Grid>
        </Container>
      </Layout>
      {/* </div> */}
    </>
  );
}
